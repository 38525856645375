.msCtn{
  position: relative;
  padding: 5px 12px;
  height: auto;
}

.requiredMsCtn{
  background-color: #ffebeb
}

.msCtnFocus{
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
  .selectItem:hover{
    border: 1px solid #BBB;
  }
}

.msCloseBtn{
  width: 7px;
  cursor: pointer;
  height: 7px;
  float: right;
  margin: 6px 2px 0 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAOAgMAAACeM+6IAAAACVBMVEUAAADMzMyIiIgKKDACAAAAAXRSTlMAQObYZgAAAClJREFUCB1jVGBgXMHAqFXGyFUGIoFsoIgAA2MIA6OoNSOrNYgEsgUYAHuKBQd4skLIAAAAAElFTkSuQmCC);
  background-position: 0 -7px;
  &:hover{
    background-position: 0 0;
  }
}

.msCtnDisabled{
  cursor: not-allowed;
  background-color: #eeeeee;
  input, .msTrigger{
    cursor: not-allowed;
    background-color: #eee;
  }
}

.msInput{
  cursor: pointer;
  border: 0;
  box-shadow: none;
  transition: none;
  outline: none;
  display: block;
  padding: 0;
  line-height: 1.42857143;
  margin: 1px 0;
  width: 100%;
  float: left;
  &:disabled{
    cursor: not-allowed;
  }
  &:focus{
    background: #FFF;
  }
}

.msTrigger{
  top: 0;
  width: 25px;
  height:100%;
  position:absolute;
  right:0;
  background: transparent;
  border-left: 1px solid #CCC;
  cursor: pointer;
  &:hover{
    background-color: #e6e6e6;
    width:24px;
    right: 1px;
    border-radius: 0 3px 3px 0;
  }
}

.msTriggerIco{
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #333;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  margin-left: 8px;
  margin-top: 15px;
}

.msSelCtn{
  overflow: auto;
  line-height: 18px;
  padding-right: 25px;
  margin-left: -7px;
}

.selectItem{
  background: #F3F3F3;
  color: #999;
  float: left;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid #DDD;
  margin: 1px 5px 1px 0;
  &:hover{
    border: 1px solid transparent;
  }
}
